// Dependency
import React from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp';

// Components
import Layout from "../../components/layouts";

// Styles
import * as marqueeStyles from '../../components/marquee.module.scss'
import * as vipStyles from "./vip.module.scss";

// Assets
import hero from "../../images/vip-hero.jpg"
import fire from "../../images/fire.png"

// Template
export default class Vip extends React.Component {    // Translation Function

    state = {
        name: null,
        email: null,
    }

    _handleChange = e => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault()
        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                if (result !== 'success') {
                    throw msg
                }
                alert(msg)
            })
            .catch(err => {
                alert(err)
            })
    }
    //
    render() {
        const translations = {
            fr: {
                input: "Your Email address...",
                button: "Sign Up",
                title: "Black Week Early Access",
                subtitle: "Sign up to get early access for Black Week"
            },
            eu: {
                input: "Your Email address...",
                button: "Sign Up",
                title: "Black Week Early Access",
                subtitle: "Sign up to get early access for Black Week"
            },
            se: {
                input: "Skriv in din e-mailadress...",
                button: "Registrera",
                title: "Black Week Early Access",
                subtitle: "Signa upp och få exklusiv förtur till Black Week",
            },
            dk: {
                input: "Din Email adresse...",
                button: "Tilmeld",
                title: "Black Week Tidlig Adang",
                subtitle: "Tilmelde dig for at få tidlig adgang for Black Week",
            },
            no: {
                input: "Your Email address...",
                button: "Sign Up",
                title: "Black Week Early Access",
                subtitle: "Sign up to get early access for Black Week"
            },
            nl: {
                input: "Your Email address...",
                button: "Sign Up",
                title: "Black Week Early Access",
                subtitle: "Sign up to get early access for Black Week"
            },
        }
        return (
            <Layout country={this.props.pageContext.country} location={this.props.location}>
                <div className={vipStyles.cdVipHero}>
                    <img alt="Black Week Early Access" src={hero} />
                    <div className={vipStyles.cdVipHeroContent}>
                        <div className={`${marqueeStyles.cdMarqueeWrapper}`}>
                            <div className={`${marqueeStyles.marquee} ${vipStyles.cdMarqueeVip}`} >
                                <div className={marqueeStyles.marquee__content}>
                                    <ul className={marqueeStyles.listInline}>
                                        <li>vip access</li>
                                        <li><span>vip access</span></li>
                                        <li>vip access</li>
                                    </ul>
                                    <ul className={marqueeStyles.listInline}>
                                        <li><span>vip access</span></li>
                                        <li>vip access</li>
                                        <li><span>vip access</span></li>
                                    </ul>
                                    <ul className={marqueeStyles.listInline}>
                                        <li>vip access</li>
                                        <li><span>vip access</span></li>
                                        <li>vip access</li>
                                        <li><span>vip access</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={vipStyles.cdVipPage}>
                    <div className={vipStyles.cdVipBackground}>
                        <img alt="Black Week Early Access" src={hero} />
                        <div className="cd-background-overlay"></div>
                    </div>
                    <div className="cd-max-width">
                        <div className={vipStyles.cdVipWrapper}>
                            <div className={vipStyles.cdVipContent}>
                                <img alt="Fire Icon" src={fire} />
                                <h2>{translations[this.props.pageContext.country].title}</h2>
                                <p>{translations[this.props.pageContext.country].subtitle}</p>
                                <form onSubmit={this._handleSubmit}>
                                    <input
                                        className={vipStyles.cdVipInput}
                                        type="email"
                                        onChange={this._handleChange}
                                        placeholder={translations[this.props.pageContext.country].input}
                                        name="email"
                                    />
                                    <input className={vipStyles.cdVipButton} value={translations[this.props.pageContext.country].button} type="submit" />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={`${marqueeStyles.cdMarqueeWrapper}`}>
                        <div className={`${marqueeStyles.marquee} ${vipStyles.cdMarqueeVip}`} >
                            <div className={marqueeStyles.marquee__content}>
                                <ul className={marqueeStyles.listInline}>
                                    <li>vip access</li>
                                    <li><span>vip access</span></li>
                                    <li>vip access</li>
                                </ul>
                                <ul className={marqueeStyles.listInline}>
                                    <li><span>vip access</span></li>
                                    <li>vip access</li>
                                    <li><span>vip access</span></li>
                                </ul>
                                <ul className={marqueeStyles.listInline}>
                                    <li>vip access</li>
                                    <li><span>vip access</span></li>
                                    <li>vip access</li>
                                    <li><span>vip access</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}